import React from 'react';

import agehabLogo from './assets/agehab_logo.png';
import governoMsLogo from './assets/governo_ms_logo.png';
import { InformacoesDeEndereco, SuporteModal } from '../../componentes';

const grupoEnderecos = [
  {
    endereco: 'Agência de Habitação Popular de Mato Grosso do Sul',
    descricao: 'Atendimento por telefone • 8h às 16h',
    info: '(67) 3348-3100 e 0800 647 3120',
  },
  {
    endereco: 'Fácil General Osório',
    descricao:
      'Rua Santo Ângelo, 51 - Coronel Antonino em frente ao terminal de ônibus',
    info: 'Atendimento presencial • 8h às 17h',
  },
  {
    endereco: 'Fácil Aero Rancho',
    descricao:
      'Av. Marechal Deodoro, 2606 - Aero Rancho em frente ao terminal de ônibus',
    info: 'Atendimento presencial e telefônico (67) 3352-8852 • 8h às 17h',
  },
  {
    endereco: 'Fácil Guaicurus',
    descricao:
      'Av. Gury Marques, 5111 - Universitário a 100 metros do terminal de ônibus',
    info: 'Atendimento presencial e telefônico (67)3388-9377 • 8h às 17h',
  },
];

const PaginaDeEntrada = ({ history }) => (
  <section className='pagina-de-entrada_new'>
    <div className='pagina-de-entrada_new-agehab'>
      <div className='pagina-de-entrada_new-agehab-logos'>
        <img src={governoMsLogo} alt='Logo Governo do Mato Grosso do Sul' />
        <img src={agehabLogo} alt='Logo AGEHAB' />
      </div>

      <div className='pagina-de-entrada_new-agehab-atendimento'>
        <span>Unidades de Atendimento</span>

        <div>
          {grupoEnderecos.map((item) => (
            <InformacoesDeEndereco
              endereco={item.endereco}
              descricao={item.descricao}
              info={item.info}
            />
          ))}
        </div>
      </div>

      <dgx-brand name='habix' size='2' inverse='true'></dgx-brand>
    </div>

    <div className='pagina-de-entrada_new-cta'>
      <div className='pagina-de-entrada_new-cta-title'>
        <dgx-heading
          text='Para iniciar, selecione uma das opções:'
          color='default'
          size='6'
          weight='medium'
          as='h2'
        ></dgx-heading>
      </div>
      <div className='pagina-de-entrada_new-cta-group'>
        <dgx-shortcut
          icon='news2Paper'
          name='Começando agora?'
          description='Separe os documentos e inicie seu cadastro'
          size='2'
          onClick={() => history.push('cadastro')}
        ></dgx-shortcut>
        <dgx-shortcut
          icon='penEdit1'
          name='Já possui inscrição?'
          description='Consulte e atualize seu cadastro'
          size='2'
          onClick={() => history.push('login')}
        ></dgx-shortcut>
      </div>

      {/* //TODO: Criar variant do button com aplicação de ícone */}
      <SuporteModal />
    </div>
  </section>
);

export default PaginaDeEntrada;
