import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoff } from '../../../servicos/autenticacao';
import { BotTrap } from '../../../componentes';
import { efetuarLogin, logOut } from '../../../actions/login.actions';
import validacao from './validacao';
import '../../../estilos/estilos.scss';

import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import {
  verificarSeAceitouOsTermosDeUso,
  redirecionarParaAceitacaoDeTermosDeUso
} from '../../../servicos/termoDeUso';
import PropTypes from 'prop-types';
import { mapearCidadesParaSelect } from '../../../selectors';

class Login extends Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
    this.inputCpfRef = React.createRef();
    this.inputSenhaRef = React.createRef();

    this.state = {
      erros: {},
      dadosLogin: {}
    };
  }

  componentDidMount = () => {
    logoff();

    if (this.selectRef.current) {
      this.selectRef.current.onSelectChange = this.atualizarCidade;
    }

    if (this.inputCpfRef.current) {
      this.inputCpfRef.current.onTextFieldChange = this.onChange;
    }

    if (this.inputSenhaRef.current) {
      this.inputSenhaRef.current.onTextFieldChange = this.onChange;
      this.inputSenhaRef.current.onClickForgetPassword = () =>
        this.props.history.push('redefinir-senha');
    }

    document.querySelector('#foco-inicial-do-teclado').focus();
  };

  onBotTrapRef = (input) => {
    this.botTrapInput = input;
  };

  onBlur = (event) => {
    const { name, value } = event.target;
    const { erros, dadosLogin } = this.state;
    const erro = validacao.validarCampo(dadosLogin, name, value);
    if (erro) {
      this.setState({
        erros: { ...erros, [name]: erro }
      });
    }
  };

  onFocus = (event) => this.removerErro(event.target.name);

  removerErro = (campo) => {
    const erros = Object.assign({}, this.state.erros);
    delete erros[campo];
    this.setState({ erros });
  };

  onChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      dadosLogin: {
        ...this.state.dadosLogin,
        [name]: value
      }
    });
    this.removerErro(name);
  };

  atualizarCidade = (codigoDaCidade) => {
    this.setState({
      dadosLogin: {
        ...this.state.dadosLogin,
        cidade: codigoDaCidade
      }
    });
    this.removerErro('cidade');
  };

  validarFormulario = () => {
    const dadosLogin = this.state.dadosLogin;
    const erros = validacao.validarFormulario(dadosLogin);

    this.setState({ erros });

    return !Object.keys(erros).length;
  };

  efetuarLogin = async (event) => {
    event.preventDefault();

    const { dadosLogin } = this.state;

    if (this.botTrapInput.value) {
      document.location.replace('https://www.google.com.br/');
    }

    if (!this.validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor informe os campos obrigatórios.'
      });
      return;
    }

    try {
      const aceitouOsTermosDeUso = await this.props.verificarSeAceitouOsTermosDeUso(dadosLogin.cpf);
      if (aceitouOsTermosDeUso) {
        await this.props.efetuarLogin(dadosLogin);
        return;
      }
      await this.props.redirecionarParaAceitacaoDeTermosDeUso(dadosLogin);
    } catch (e) {
      if (!e.response || !e.response.data || e.response.mensagem) {
        const mensagemErro = e.response.mensagem ? e.response.mensagem : 'Não foi possível prosseguir com sua incrição. Tente novamente. Caso o problema persista, entre em contato com o suporte.';
        this.setState({ mensagemErro });
        return;
      }
    }
  };

  limparMensagemDeErro = () => {
    this.setState({
      mensagemErro: false
    });
  };

  render() {
    const inputEvents = {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus
    };
    const {
      dadosLogin: {
        cpf = ''
      },
      erros, mensagemErro
    } = this.state;

    const { history, cidades } = this.props;

    const arrayComoString = JSON.stringify(cidades);

    return (
      <section className="atualizar-inscricao_new" id="foco-inicial-do-teclado">
        <div className="atualizar-inscricao_new-bg">
          <dgx-brand name="habix" size="2" inverse="true"></dgx-brand>
        </div>

        <div className="atualizar-inscricao_new-form">
          <div className="atualizar-inscricao_new-form-wrapper">
            <div className="atualizar-inscricao_new-form-wrapper-title">
              <dgx-heading
                text="Consulte e atualize"
                color="default"
                size="6"
                weight="medium"
                as="h2"
              ></dgx-heading>
              <dgx-text
                text="Informe os seus dados para acessar a sua inscrição"
                color="neutral"
                size="2"
                weight="regular"
                as="p"
              ></dgx-text>
            </div>
            <form className="">
              <div className="atualizar-inscricao_new-form-wrapper-input">
                <dgx-select
                  ref={this.selectRef}
                  text="Selecione sua cidade"
                  variant="surface"
                  size="3"
                  label="Cidade"
                  error-Message="Selecione uma cidade"
                  options={arrayComoString}
                ></dgx-select>
                <dgx-textfield
                  ref={this.inputCpfRef}
                  name="cpf"
                  placeholder="Digite o seu CPF"
                  variant="classic"
                  size="3"
                  type="cpf"
                  label="Seu CPF"
                  error-Message="Digite um CPF valido"
                ></dgx-textfield>
              </div>
              <div className="atualizar-inscricao_new-form-wrapper-input">
                <dgx-textfield
                  ref={this.inputSenhaRef}
                  name="senha"
                  placeholder="*******"
                  variant="classic"
                  size="3"
                  type="password"
                  label="Senha"
                ></dgx-textfield>
              </div>
              <BotTrap onRef={this.onBotTrapRef} />
              <div className="atualizar-inscricao_new-form-wrapper-button">
                <dgx-button
                  text="Avançar"
                  full="true"
                  onClick={this.efetuarLogin}
                  type="button"
                ></dgx-button>
                <dgx-button
                  text="Não tenho senha de acesso"
                  variant="surface"
                  full="true"
                  onClick={() => history.push('/redefinir-senha')}
                  type="button"
                ></dgx-button>
              </div>
            </form>
            <div className="atualizar-inscricao_new-form-wrapper-link">
              <dgx-text
                text="Não tem inscrição?"
                size={2}
                color="default"
                weight="medium"
              ></dgx-text>
              <a href="/cadastro" onClick={() => history.push('cadastro')}>
                Inscreva-se
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Login.propTypes = {
  cidades: PropTypes.array,
  efetuarLogin: PropTypes.func
};

const mapStateToProps = ({ cidades }) => ({
  cidades: mapearCidadesParaSelect(cidades)
});

const mapDispatchToProps = (dispatch) => ({
  efetuarLogin: (dadosLogin) => dispatch(efetuarLogin(dadosLogin)),
  logOut: () => dispatch(logOut()),
  verificarSeAceitouOsTermosDeUso: (cpfDoTitular) =>
    verificarSeAceitouOsTermosDeUso(cpfDoTitular, dispatch),
  redirecionarParaAceitacaoDeTermosDeUso: (dadosDeLogin) =>
    redirecionarParaAceitacaoDeTermosDeUso(dadosDeLogin, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
