import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
//@ts-ignore
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import { Header } from '../../componentes';
import Container from '../../componentes/Container';
import FormularioDeEndereco from '../../componentes/FormularioDeEndereco';
import ValidacaoFormularioDeEndereco from '../../componentes/FormularioDeEndereco/validacao';
import PerguntasDeMoradia from '../../componentes/PerguntasDeMoradia';
import ValidacaoPerguntasDeMoradia from '../../componentes/PerguntasDeMoradia/validacao';
import * as moradiaActions from '../../actions/moradia.actions';
import acessoFeitoNoResumoDaInscricao from '../../helpers/verificadorDeAcessoPeloResumo';
import '../../estilos/estilos.scss';
import initialState from '../../reducers/initialState.reducer';
import IMoradia from '../../interfaces/IMoradia';
import IEstado from '../../interfaces/IEstado';
import IEndereco from '../../interfaces/IEndereco';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import ICidade from '../../interfaces/ICidade';
import { IFormularioDeEnderecoValidacaoErroCampos } from '../../interfaces/IFormularioDeEnderecoValidacao';
import { IPerguntaDeMoradiaValidacaoErroCampos } from '../../interfaces/IPerguntaDeMoradiaValidacao';
import { salvarMoradia } from '../../servicos/moradia';
import ICidadeParaSelect from '../../interfaces/ICidadeParaSelect';
import { mapearCidadesParaSelect } from '../../selectors';

const Endereco = () => {
  const iniciarMoradia = () => {
    if (!ehNovaInscricao && Object.keys(moradia).length == 0)
      return {} as IMoradia;

    if (ehNovaInscricao && !moradia.endereco?.logradouro) {
      //@ts-ignore
      const idcidade = moradia.endereco?.cidade ? moradia.endereco.cidade.id : login.cidade;
      const cidade = { id: idcidade } as ICidade;
      const estado = { uf: 'MS' } as IEstado;
      const endereco = { estado, cidade } as IEndereco;
      moradia.endereco = endereco;
      moradia.areasDeRisco = [];
      moradia.infraestruturas = [];
    }

    if (!moradia.endereco?.estado)
      moradia.endereco.estado = { uf: 'MS' } as IEstado;

    if (!moradia.endereco?.cep) {
      moradia.endereco.cep = moradia.endereco?.cidade.cepPadrao || '';
    }
    return moradia;
  };

  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const moradia = useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const login = useSelector((state: typeof initialState) => state.login);
  const [dadosMoradia, setDadosMoradia] = useState<IMoradia>(iniciarMoradia);
  const [tentandoSalvar, setTentandoSalvar] = useState<boolean>(false);
  const ehAcessoAdministrativo = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo) as boolean;
  const [moraEmDistrito, setMoraEmDistrito] = useState<boolean>(Object.keys(moradia).length > 0 && dadosMoradia.endereco?.distrito != null && Object.keys(dadosMoradia.endereco?.distrito).length > 0 ? true : false);
  const [moraEmAreaDeRisco, setMoraEmAreaDeRisco] = useState<boolean>(Object.keys(moradia).length > 0 && dadosMoradia.areasDeRisco.length > 0);
  const [errosFormularioDeEndereco, setErrosFormularioDeEndereco] = useState<IFormularioDeEnderecoValidacaoErroCampos | undefined>();
  const [errosPerguntaDeMoradia, setErrosPerguntaDeMoradia] = useState<IPerguntaDeMoradiaValidacaoErroCampos | undefined>();
  const verificarCepPadrao = () => Object.keys(moradia).length > 0 && (!!dadosMoradia.endereco?.cidade?.cepPadrao || !!dadosMoradia.endereco.distrito?.cepPadrao);
  const [ehCepPadrao, setEhCepPadrao] = useState<boolean>(verificarCepPadrao);
  const cidades = useSelector((state: typeof initialState) => mapearCidadesParaSelect(state.cidades) as ICidadeParaSelect[]);
  const bloquearMudancaDeCidade = ehAcessoAdministrativo ? false : Object.keys(moradia).length > 0 && dadosMoradia.endereco.cidade?.id != null;
  const dispatch = useDispatch();
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  useEffect(() => {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));

  }, [dispatch, ehFormularioSomenteLeitura, ehNovaInscricao, inscricaoEstaVazioNaMemoria]);

  const limparAreasDeRisco = () => {
    setDadosMoradia({ ...dadosMoradia, areasDeRisco: [] });
    setMoraEmAreaDeRisco(false);
  };

  const atualizarComDadosDoEndereco = (endereco: IEndereco) => {
    setDadosMoradia({ ...dadosMoradia, endereco: endereco });
    removerErro('cep');
    if (!!endereco.logradouro)
      removerErro('logradouro');
    if (!!endereco.bairro)
      removerErro('bairro');
  };

  const validarCepDaCidade = (cidade: number) => {
    let cepDasCidadesSaoDiferentes = false;
    const ehCepPadrao = !!dadosMoradia.endereco.cidade.cepPadrao;
    const existeCodigoDaCidade = cidade !== undefined && dadosMoradia.endereco !== undefined && cidade !== 0;

    if (!ehCepPadrao && existeCodigoDaCidade) {
      cepDasCidadesSaoDiferentes = cidade !== dadosMoradia.endereco.cidade.id;
    }
    return cepDasCidadesSaoDiferentes;
  };

  const validarFormulario = () => {
    const endereco = dadosMoradia.endereco;

    const errosFormularioDeEndereco = ValidacaoFormularioDeEndereco.validarFormulario(endereco.cep, endereco.logradouro, endereco.numero,
      endereco.bairro, endereco.cidade?.id, moraEmDistrito, endereco.distrito?.id);

    setErrosFormularioDeEndereco(errosFormularioDeEndereco);

    const errosPerguntasDeMoradia = ValidacaoPerguntasDeMoradia.validarFormulario(dadosMoradia.situacao, dadosMoradia.valorDoAluguel, dadosMoradia.materialDaConstrucao,
      moraEmAreaDeRisco, dadosMoradia.areasDeRisco, dadosMoradia.possuiOnusExcessivo, dadosMoradia.possuiAdensamentoExcessivo);

    setErrosPerguntaDeMoradia(errosPerguntasDeMoradia);

    return Object.keys(errosFormularioDeEndereco).length > 0 || Object.keys(errosPerguntasDeMoradia).length > 0;
  };

  const exibirMensagemDeErroDeCepDaCidade = () => {
    setTentandoSalvar(false);
    GerenciadorDeMensagem.criarMensagem({
      tipo: 'erro',
      icone: 'far fa-times-circle',
      titulo: 'Identificamos um erro:',
      texto: 'Formulário inválido. CEP não corresponde com a cidade selecionada!'
    });
  };

  const posicionarTela = () => {
    if (tentandoSalvar) {
      let campoComErro: HTMLElement | null = document.querySelector('.formulario__mensagem_erro');

      if (campoComErro) {
        const alturaDoCabecalhoDaPagina = 115;
        campoComErro.scrollIntoView({ behavior: 'smooth' });
        window.scrollBy(0, -alturaDoCabecalhoDaPagina);
      }
      setTentandoSalvar(false);
    }
  };

  const clickSalvar = async () => {
    setTentandoSalvar(true);

    const haErroNoCepDaCidade = validarCepDaCidade(dadosMoradia.endereco.cidade.id);
    if (haErroNoCepDaCidade) {
      exibirMensagemDeErroDeCepDaCidade();
      return;
    }

    const haErroNoFormulario = validarFormulario();
    if (haErroNoFormulario) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos obrigatórios.'
      });
      posicionarTela();
      return;
    }

    await salvarMoradia(dadosMoradia, idDaInscricao);

    dispatch(moradiaActions.salvarMoradiaSucesso(dadosMoradia));

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Endereço cadastrado com sucesso.'
    });

    if (ehNovaInscricao && !acessoFeitoNoResumoDaInscricao()) {
      dispatch(push('/imoveis'));
      return;
    } else
      dispatch(push('/menu-inscricao'));
  };

  const onBlurFormularioDeEndereco = (evento: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = evento.target;
    const endereco = dadosMoradia.endereco;
    const erro = ValidacaoFormularioDeEndereco.validarCampo(name, endereco.cep, endereco.logradouro, endereco.numero, endereco.bairro,
      endereco.cidade?.id, moraEmDistrito, endereco.distrito?.id);
    if (erro)
      //@ts-ignore
      setErrosFormularioDeEndereco({ ...errosFormularioDeEndereco, [name]: erro });
  };

  const onBlurPerguntaDeMoradia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const erro = ValidacaoPerguntasDeMoradia.validarCampo(name, dadosMoradia.situacao, dadosMoradia.valorDoAluguel, dadosMoradia.materialDaConstrucao,
      moraEmAreaDeRisco, dadosMoradia.areasDeRisco);

    if (erro)
      //@ts-ignore
      setErrosPerguntaDeMoradia({ ...errosPerguntaDeMoradia, [name]: erro });
  };

  const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    removerErro(event.target.name);
  };

  const removerErro = (...campos: string[]): void => {
    if (campos == undefined || errosFormularioDeEndereco == undefined || errosPerguntaDeMoradia == undefined) return;

    campos.forEach((campo: string) => {
      //@ts-ignore
      errosFormularioDeEndereco[campo] = undefined;
      //@ts-ignore
      errosPerguntaDeMoradia[campo] = undefined;
    });
    setErrosFormularioDeEndereco(errosFormularioDeEndereco);
    setErrosPerguntaDeMoradia(errosPerguntaDeMoradia);
  };

  const atualizarMoraEmDistrito = (moraEmDistrito: boolean) => {
    setMoraEmDistrito(moraEmDistrito);
  };

  const atualizarCepPadrao = (ehCepPadrao: boolean) => {
    setEhCepPadrao(ehCepPadrao);
  };

  const atualizarInfraestrutura = (infraestruturas: string[]) => {
    setDadosMoradia({ ...dadosMoradia, infraestruturas: infraestruturas });
  };

  const atualizarAreasDeRisco = (areasDeRisco: string[]) => {
    setDadosMoradia({ ...dadosMoradia, areasDeRisco: areasDeRisco });
    if (areasDeRisco.length > 0)
      removerErro('areasDeRisco');
  };

  const atualizarSituacaoMoradia = (situacaoMoradia: string) => {
    if (situacaoMoradia != 'Alugada'){
      if (situacaoMoradia == 'EmSituacaoDeRua')
        setDadosMoradia({ ...dadosMoradia, situacao: situacaoMoradia, valorDoAluguel: undefined, possuiOnusExcessivo: undefined, materialDaConstrucao: '' });
      else
        setDadosMoradia({ ...dadosMoradia, situacao: situacaoMoradia, valorDoAluguel: undefined});
    }
    else 
      setDadosMoradia({ ...dadosMoradia, situacao: situacaoMoradia });
  };

  const atualizarValorDoAluguel = (valorDoAluguel: number) => {
    setDadosMoradia({ ...dadosMoradia, 'valorDoAluguel': valorDoAluguel });
  };

  const atualizarMoraEmAreaDeRisco = (moraAreaDeRisco: boolean) => {
    setMoraEmAreaDeRisco(moraAreaDeRisco);
  };

  const atualizarMaterialDaConstrucao = (materialDaConstrucao: string) => {
    setDadosMoradia({ ...dadosMoradia, 'materialDaConstrucao': materialDaConstrucao });
  };

  const atualizarAdensamentoExcessivo = (adensamentoExcessivo: boolean) => {
    setDadosMoradia({ ...dadosMoradia, possuiAdensamentoExcessivo: adensamentoExcessivo });
    removerErro('adensamentoExcessivo');
  };

  const atualizarOnusExcessivo = (onusExcessivo: boolean) => {
    setDadosMoradia({ ...dadosMoradia, possuiOnusExcessivo: onusExcessivo });
    removerErro('onusExcessivo');
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) &&
        <div className="pagina-endereco">
          {!ehAcessoAdministrativo && (
            //@ts-ignore
            <Header subTitulo={'Cadastro - Endereço'} comBotaoVoltar={!!dadosMoradia.endereco} urlBotaoVoltar={'/menu-inscricao'} />
          )}
          {ehAcessoAdministrativo && (
            <BarraDeNavegacao
              titulo='Cadastro - Endereço'
              comBotaoVoltar={true}
              acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
              comBotaoADireita={true}
              textoBotaoADireita="Menu de inscrição"
              iconeBotaoADireita={false}
              acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
            />
          )}

          <div className="pagina-pretendente__container">
            <Container comFundo={false} semPadding={true} tamanhoDoContainer={''} >
              <FormularioDeEndereco
                titulo="Endereço atual"
                dadosDoEndereco={dadosMoradia.endereco}
                erros={errosFormularioDeEndereco}
                bloquearMudancaDeCidade={bloquearMudancaDeCidade}
                moraEmDistrito={moraEmDistrito}
                ehCepPadrao={ehCepPadrao}
                atualizarComDadosDoEndereco={atualizarComDadosDoEndereco}
                atualizarErros={setErrosFormularioDeEndereco}
                atualizarMoraEmDistrito={atualizarMoraEmDistrito}
                atualizarCepPadrao={atualizarCepPadrao}
                onBlur={onBlurFormularioDeEndereco}
                cidades={cidades}
                disabled={ehFormularioSomenteLeitura} />
              <PerguntasDeMoradia
                dadosMoradia={dadosMoradia}
                moraEmAreaDeRisco={moraEmAreaDeRisco}
                erros={errosPerguntaDeMoradia}
                limparAreasDeRisco={limparAreasDeRisco}
                onBlur={onBlurPerguntaDeMoradia}
                onFocus={onFocus}
                onClick={clickSalvar}
                atualizarInfraestrutura={atualizarInfraestrutura}
                atualizarAreasDeRisco={atualizarAreasDeRisco}
                atualizarSituacaoMoradia={atualizarSituacaoMoradia}
                atualizarValorDoAluguel={atualizarValorDoAluguel}
                atualizarMoraEmAreaDeRisco={atualizarMoraEmAreaDeRisco}
                atualizarErros={setErrosPerguntaDeMoradia}
                atualizarMaterialDaConstrucao={atualizarMaterialDaConstrucao}
                disabled={ehFormularioSomenteLeitura}
                atualizarAdensamentoExcessivo={atualizarAdensamentoExcessivo} 
                atualizarOnusExcessivo={atualizarOnusExcessivo}
              />
            </Container>
          </div>
        </div>}
    </>
  );
};
export default Endereco;