import React, { Component } from 'react';
import {
  CampoDeTexto,
  HeaderSenhaLogo,
  LayoutDaPaginaDeEntrada,
  SuporteModal
} from '../../../componentes';
import { BotaoComTexto } from '@bit/digix.digixui.botao';
import { LinkComIcone } from '@bit/digix.digixui.link';
import Mensagem from '@bit/digix.digixui.mensagem';
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import {
  apiDaInscricao,
  apiDaInscricaoSemLoading
} from '../../../servicos/axios';
import { manterSomenteNumeros } from '../../../helpers/mascaras';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { endAjaxCall } from '../../../actions/requisicoesAjax.actions';

class ConfirmacaoDeCodigo extends Component {
  constructor(props) {
    super(props);
    this.codeInputRef = React.createRef();
    this.state = {
      contador: 59,
      mensagem: '',
      podeReenviarCodigo: false,
      tempoParaReenviarOCodigo: 60000
    };
  }

  componentDidMount() {
    document.querySelector('#foco-inicial-do-teclado').focus();

    setTimeout(
      () => this.setState({ podeReenviarCodigo: true }),
      this.state.tempoParaReenviarOCodigo
    );

    if (this.codeInputRef.current) {
      this.codeInputRef.current.onCodeInputChange = this.onChange;
    }

    this.iniciarContador();
  }

  iniciarContador = () => {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.contador === 1) {
          clearInterval(this.intervalId);
          return { contador: 0, mensagem: 'Reenvia' };
        }
        if (prevState.contador % 10 === 0) {
        }
        return { contador: prevState.contador - 1 };
      });
    }, 1000);
  };

  reenviarCodigoEAlterarVisibilidadeDoReenvio = async (
    cpf,
    tipoDeContatoParaConfirmacao
  ) => {
    let cpfComApenasNumeros = manterSomenteNumeros(cpf);
    this.alterarVisibilidadeDoReenvioDoCodigo();
    await this.reenviarCodigo(
      cpfComApenasNumeros,
      tipoDeContatoParaConfirmacao
    );
    this.reiniciarContador();
  };

  alterarVisibilidadeDoReenvioDoCodigo = () => {
    this.setState(
      (prevState) => ({
        podeReenviarCodigo: !prevState.podeReenviarCodigo
      }),
      () => {
        setTimeout(
          () =>
            this.setState({
              podeReenviarCodigo: !this.state.podeReenviarCodigo
            }),
          this.state.tempoParaReenviarOCodigo
        );
      }
    );
  };

  reenviarCodigo = async (
    cpfComApenasNumeros,
    tipoDeContatoParaConfirmacao
  ) => {
    let mensagemDeReenvioDeCodigo = {};
    try {
      await apiDaInscricaoSemLoading().post(
        `/titulares/${cpfComApenasNumeros}/solicitacaoDeCodigo/${tipoDeContatoParaConfirmacao}`
      );
      mensagemDeReenvioDeCodigo = {
        tipo: 'sucesso',
        icone: 'far fa-check-circle',
        texto: 'Enviamos o código de confirmação para o contato informado',
        titulo: 'Tudo certo!'
      };
    } catch (e) {
      let mensagemDeErroDaResposta;
      if (!e.response || !e.response.data) {
        mensagemDeErroDaResposta =
          'Não foi possível enviar o código de confirmação no momento. Caso o problema persista entre em contato com o suporte.';
      } else {
        mensagemDeErroDaResposta =
          typeof e.response.data === 'string'
            ? e.response.data
            : e.response.data.mensagens[0];
      }
      mensagemDeReenvioDeCodigo = {
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: mensagemDeErroDaResposta
      };
    }

    GerenciadorDeMensagem.criarMensagem(mensagemDeReenvioDeCodigo);
  };

  confirmarCodigo = async (cpf, event) => {
    event.preventDefault();
    const { codigo } = this.state;

    if (!codigo) {
      this.setState({
        erroNoCampoDoCodigo: 'Insira o código recebido para confirmar'
      });
      return;
    }

    const cpfComApenasNumeros = manterSomenteNumeros(cpf);
    const codigoComLetrasMaiusculas = codigo.toUpperCase();
    try {
      await apiDaInscricao().post(
        `/titulares/${cpfComApenasNumeros}/confirmacaoDeCodigo`,
        { codigoDeConfirmacao: codigoComLetrasMaiusculas }
      );

      this.props.endAjaxCall();
      this.props.history.push('/definir-senha', {
        cpf: cpfComApenasNumeros,
        codigo: codigoComLetrasMaiusculas
      });
    } catch (e) {
      this.setState({
        erroNoCampoDoCodigo:
          'Não foi possível confirmar o código. Tente novamente.'
      });
    }
  };

  onFocus = () => this.removerErro();
  reiniciarContador = () => {
    this.setState({ contador: 59 });
    this.iniciarContador();
  };
  removerErro = () => {
    this.setState({
      erroNoCampoDoCodigo: ''
    });
  };

  onChange = (value) => {
    this.setState({
      codigo: value
    });
    this.removerErro();
  };

  render() {
    const inputEvents = {
      onChange: this.onChange,
      onFocus: this.onFocus
    };
    const { contador, mensagem, erroNoCampoDoCodigo } = this.state;
    const propsRecebidos = this.props.location.state || {};
    const cpf = propsRecebidos.cpf;
    const telefoneCelularComDigitosOcultos =
      propsRecebidos.telefoneCelularComDigitosOcultos || '';
    const emailComCaracteresOcultos =
      propsRecebidos.emailComCaracteresOcultos || '';
    const tipoDeContatoParaConfirmacao =
      propsRecebidos.tipoDeContatoParaConfirmacao || '';

    return (
      <div>
        <HeaderSenhaLogo />
        <div className="pagina-confirmacao">
          <div className="pagina-confirmacao-codigo">
            <div>
              <div className="pagina-confirmacao-codigo-header">
                <div>
                  <div
                    onClick={() => this.props.history.goBack()}
                    className="pagina-confirmacao-codigo-voltar"
                  >
                    <dgx-icon name="arrowChevronLeft" size="3"></dgx-icon>
                    <dgx-text
                      text="Voltar"
                      color="accent"
                      size="3"
                      weight="regular"
                      as="a"
                    />
                  </div>
                </div>
                <dgx-heading
                  text="Código de validação"
                  color="default"
                  size="6"
                  weight="medium"
                  as="h1"
                />
                {tipoDeContatoParaConfirmacao === 'sms' && (
                  <dgx-text
                    text={`Em breve você receberá um SMS em <strong>${telefoneCelularComDigitosOcultos}</strong> o código para criar a sua senha. Preencha-o abaixo:`}
                    color="neutral"
                    size="2"
                    weight="regular"
                    as="p"
                  />
                )}

                {tipoDeContatoParaConfirmacao === 'email' && (
                  <dgx-text
                    text={`Em breve você receberá em <strong>${emailComCaracteresOcultos}</strong> o código para criar a sua senha. Preencha-o abaixo:`}
                    color="neutral"
                    size="2"
                    weight="regular"
                    as="p"
                  />
                )}
              </div>
            </div>

            <div className="pagina-confirmacao-codigo-input">
              <div>
                <form>
                  <div className="pagina-confirmacao-codigo-callout">
                    {erroNoCampoDoCodigo && (
                      <dgx-callout
                        size="2"
                        variant="soft"
                        icon-name="infoInformation"
                        color="error"
                        message="Código inválido. Verifique e tente novamente."
                      ></dgx-callout>
                    )}
                  </div>

                  <dgx-codeinput ref={this.codeInputRef} />
                  <div className="pagina-confirmacao-codigo-button">
                    <dgx-button
                      text="Validar código"
                      variant="solid"
                      color="accent"
                      size="3"
                      full="true"
                      type="button"
                      onClick={(event) => this.confirmarCodigo(cpf, event)}
                    />
                  </div>
                  <div
                    id="foco-inicial-do-teclado"
                    className="pagina-confirmacao-codigo-links"
                  >
                    <dgx-text
                      text="Não recebeu o código?"
                      color="default"
                      size="2"
                      weight="medium"
                      as="p"
                    ></dgx-text>
                    <div className="pagina-confirmacao-codigo-temporizador">
                      {contador > 0 && (
                        <dgx-icon name="alarmTimerStyle2Right" size="3" />
                      )}

                      <div
                        className={
                          contador > 0
                            ? 'texto-reenviar-disabled'
                            : 'texto-reenviar'
                        }
                      >
                        <dgx-text
                          text={
                            contador > 0
                              ? `${contador}s para poder reenviar`
                              : mensagem
                          }
                          color={contador > 0 ? 'neutral' : 'accent'}
                          size="2"
                          weight="medium"
                          as="p"
                          onClick={() =>
                            this.reenviarCodigoEAlterarVisibilidadeDoReenvio(
                              cpf,
                              tipoDeContatoParaConfirmacao
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {tipoDeContatoParaConfirmacao === 'email' && contador > 0 && (
                    <>
                      <dgx-callout
                        size="1"
                        variant="soft"
                        icon-name="mail"
                        color="warning"
                        message="E-mail não chegou?"
                        description="Verifique sempre a pasta de spam e lixeira da sua caixa de e-mail."
                      ></dgx-callout>
                    </>
                  )}
                </form>
              </div>

              <SuporteModal />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { endAjaxCall })(ConfirmacaoDeCodigo);
