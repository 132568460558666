import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BotTrap,
  ValidadorDeSenha
} from '../../../componentes';
import { mapearCidadesParaSelect } from '../../../selectors';
import { iniciarCadastroDeNovaInscricao } from '../../../actions/inscricao.actions';
import validacao from './validacao';
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import PropTypes from 'prop-types';
class CadastroDeInscricao extends Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
    this.inputCpfRef = React.createRef();
    this.inputSenhaRef = React.createRef();
    this.inputConfirmeSenhaRef = React.createRef();

    this.state = {
      erros: {},
      dadosDoCadastro: {
        cidade: '',
        cpf: '',
        senha: '',
        confirmacaoDeSenha: ''
      },
      capsLockEstaAtivo: false
    };
  }

  componentDidMount() {
    document.querySelector('#foco-inicial-do-teclado').focus();


    if (this.selectRef.current) {
      this.selectRef.current.onSelectChange = this.atualizarCidade;
    }

    if (this.inputCpfRef.current) {
      this.inputCpfRef.current.onTextFieldChange = this.onChange;
    }

    if (this.inputSenhaRef.current) {
      this.inputSenhaRef.current.onTextFieldChange = this.onChange;
    }

    if (this.inputConfirmeSenhaRef.current) {
      this.inputConfirmeSenhaRef.current.onTextFieldChange = this.onChange;
    }
  }

  onBotTrapRef = (input) => {
    this.botTrapInput = input;
  };

  onChange = (evento) => {
    const { name, value } = evento.target;

    this.setState({
      dadosDoCadastro: {
        ...this.state.dadosDoCadastro,
        [name]: value
      }
    });

    this.removerErro(name);
  };

  onBlur = (evento) => {
    const { name, value } = evento.target;
    const { erros, dadosDoCadastro } = this.state;
    const erro = validacao.validarCampo(dadosDoCadastro, name, value);

    if (erro)
      this.setState({
        erros: { ...erros, [name]: erro }
      });
  };

  onFocus = (evento) => {
    const { name } = evento.target;
    this.removerErro(name);
  };

  atualizarCidade = (cidade) => {
    this.setState({
      dadosDoCadastro: {
        ...this.state.dadosDoCadastro,
        cidade
      }
    });

    this.removerErro('cidade');
  };

  removerErro = (campo) => {
    const erros = { ...this.state.erros };
    delete erros[campo];
    this.setState({ erros });
  };

  iniciarCadastroDeNovaInscricao = async (event) => {
    event.preventDefault();

    if (this.botTrapInput.value)
      document.location.replace('https://www.google.com.br/');

    if (!this.validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor informe os campos obrigatórios.'
      });
      return;
    }

    try {
      await this.props.iniciarCadastroDeNovaInscricao(
        this.state.dadosDoCadastro
      );
    } catch (e) {
      if (!e.response || !e.response.data) {
        const mensagemErro =
          'Não foi possível prosseguir com sua inscrição. Tente novamente. Caso o problema persista, entre em contato com o suporte.';

        this.setState({ mensagemErro });
        return;
      }

      const mensagemDeErro =
        typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data.mensagens[0];
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: mensagemDeErro
      });
    }
  };

  validarFormulario = () => {
    const dadosDoCadastro = this.state.dadosDoCadastro;
    const erros = validacao.validarFormulario(dadosDoCadastro);

    this.setState({ erros });

    return !Object.keys(erros).length;
  };

  limparMensagemDeErro = () => {
    this.setState({
      mensagemErro: false
    });
  };

  notificarCapsLockAtivo = (estaAtivo) => {
    this.setState({
      capsLockEstaAtivo: estaAtivo
    });
  };

  get inputEvents() {
    return {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus
    };
  }

  render() {
    const {
      dadosDoCadastro: { cpf, senha, confirmacaoDeSenha },
    } = this.state;

    const { cidades, history } = this.props;

    const arrayComoString = JSON.stringify(cidades);

    return (
      <section className="cadastro-de-inscricao_new" id="foco-inicial-do-teclado">
        <div className="cadastro-de-inscricao_new-bg">
          <dgx-brand name="habix" size="2" inverse="true"></dgx-brand>
        </div>

        <div className="cadastro-de-inscricao_new-form">
          <div className="cadastro-de-inscricao_new-form-wrapper">
            <div className="cadastro-de-inscricao_new-form-wrapper-title">
              <dgx-heading
                text="Inicie sua inscrição"
                color="default"
                size="6"
                weight="medium"
                as="h2"
              ></dgx-heading>
              <dgx-text
                text="Para iniciar o seu cadastro, insira os dados abaixo:"
                color="neutral"
                size="2"
                weight="regular"
                as="p"
              ></dgx-text>
            </div>
            <form className="">
              <div className="cadastro-de-inscricao_new-form-wrapper-input">
                <dgx-select
                  ref={this.selectRef}
                  text="Selecione sua cidade"
                  size="3"
                  label="Cidade"
                  options={arrayComoString}
                  error-message="Selecione uma cidade"
                ></dgx-select>
                <dgx-textfield
                  ref={this.inputCpfRef}
                  placeholder="Digite o seu CPF"
                  name="cpf"
                  size="3"
                  type="cpf"
                  label="Seu CPF"
                  error-message="Digite um CPF valido"
                ></dgx-textfield>
              </div>

              <div className="cadastro-de-inscricao_new-form-wrapper-password">
                <dgx-icon name="lock" size={3}></dgx-icon>
                <dgx-text
                  text="Senha de acesso"
                  color="default"
                  size="3"
                  weight="medium"
                  as="p"
                ></dgx-text>
              </div>
              <div className="cadastro-de-inscricao_new-form-wrapper-input">
                <dgx-textfield
                  ref={this.inputSenhaRef}
                  name="senha"
                  placeholder="*******"
                  size="3"
                  type="password"
                  label="Senha"
                ></dgx-textfield>
                <ValidadorDeSenha senha={this.state.dadosDoCadastro.senha} />
                <dgx-textfield
                  ref={this.inputConfirmeSenhaRef}
                  name="confirmacaoDeSenha"
                  placeholder="*******"
                  size="3"
                  type="password"
                  label="Confirme sua senha"
                ></dgx-textfield>
              </div>
              <BotTrap onRef={this.onBotTrapRef} />
              <dgx-button
                text="Avançar"
                size="3"
                full="true"
                onClick={this.iniciarCadastroDeNovaInscricao}
                type="button"
              ></dgx-button>
            </form>
            <div className="cadastro-de-inscricao_new-form-wrapper-link">
              <dgx-text
                text="Já tem uma inscrição?"
                size={2}
                color="default"
                weight="medium"
              ></dgx-text>
              <a href="/login" onClick={() => history.push('login')}>
                Ir para o login
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ cidades }) => ({
  cidades: mapearCidadesParaSelect(cidades)
});

const mapDispatchToProps = (dispatch) => ({
  iniciarCadastroDeNovaInscricao: (dadosDoCadastro) =>
    dispatch(iniciarCadastroDeNovaInscricao(dadosDoCadastro))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroDeInscricao);

CadastroDeInscricao.propTypes = {
  cidades: PropTypes.array,
  iniciarCadastroDeNovaInscricao: PropTypes.func
};
