import { useEffect, useRef } from 'react';

const SuporteModal = () => {
  const modalRef = useRef(null);

  useEffect(() => {
    console.log(modalRef.current);
  }, []);

  return (
    <>
      <div
        className="SuporteModal-suporte"
        onClick={() => modalRef.current.open()}
      >
        <dgx-icon name="headphonesCustomer" size="3"></dgx-icon>
        <dgx-text
          text="Suporte"
          color="neutral"
          size="2"
          weight="medium"
          as="p"
        ></dgx-text>
      </div>

      <dgx-modal id="modal1" size="1" ref={modalRef}>
        <div slot="nav-bar">
          <div className="header">
            <dgx-icon name="headphonesCustomer" size="3"></dgx-icon>
          </div>
        </div>
        <div slot="body">
          <div className="body">
            <dgx-heading
              text="Ficou com alguma dúvida?"
              color="Default"
              size="5"
              weight="medium"
              as="h3"
            ></dgx-heading>
            <dgx-text
              text="Entre em contato com nosso Serviço de <strong>Atendimento ao Público</strong> pelo telefone <strong>0800 647 3120</strong>"
              color="neutral"
              size="3"
              weight="regular"
              as="p"
            ></dgx-text>
          </div>
        </div>
      </dgx-modal>
    </>
  );
};

export default SuporteModal;
