import React, { Component } from 'react';
import { CampoDeTexto, LayoutDaPaginaDeEntrada, SuporteModal, ValidadorDeSenha } from '../../../componentes';
import { BotaoComTexto } from '@bit/digix.digixui.botao';
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import { connect } from 'react-redux';
import { endAjaxCall } from '../../../actions/requisicoesAjax.actions';
import { apiDaInscricao } from '../../../servicos/axios';
import {
  detectarLetrasMaiusculas,
  detectarCapsLock
} from '../../../helpers/deteccaoDeEventos';

class DefinirSenha extends Component {
  constructor(props) {
    super(props);
    this.inputSenhaRef = React.createRef();
    this.inputConfirmeSenhaRef = React.createRef();
    this.state = {
      senha: '',
      confirmacaoDeSenha: '',
      erros: [],
      capsLockEstaAtivo: false,
      regras: {
        minimoSeisCaracteres: false,
        contemNumero: false,
        contemLetra: false
      },
      senhaCriadaComSucesso: false
    };
  }
  onChange = (evento) => {
    const { name, value } = evento.target;

    this.setState({ [name]: value });

    if (name === 'senha') {
      this.validarRegrasDeSenha(value);
    }

    this.removerErro(name);
  };

  validarRegrasDeSenha = (senha) => {
    this.setState({
      regras: {
        minimoSeisCaracteres: senha.length >= 6,
        contemNumero: this.contemNumero(senha),
        contemLetra: this.contemCaractere(senha)
      }
    });
  };

  componentDidMount() {
    const senhaCriadaComSucesso = localStorage.getItem('senhaCriadaComSucesso');
    if (senhaCriadaComSucesso) {
      this.setState({ senhaCriadaComSucesso: true });
    }
    // document.querySelector('#foco-inicial-do-teclado').focus();
    if (this.inputSenhaRef.current) {
      // Passando a função diretamente via ref
      this.inputSenhaRef.current.onTextFieldChange = this.onChange;
    }

    if (this.inputConfirmeSenhaRef.current) {
      // Passando a função diretamente via ref
      this.inputConfirmeSenhaRef.current.onTextFieldChange = this.onChange;
    }
  }

  contemNumero = (palavra) => {
    return /\d/.test(palavra);
  };

  contemCaractere = (palavra) => {
    return /[a-z]/i.test(palavra);
  };

  validarSenha = (senha) => {
    const quantidadeMinimaDeCaracteres = 6;
    const quantidadeMaximaDeCaracteres = 16;

    if (senha.length > quantidadeMaximaDeCaracteres)
      return 'A senha não pode conter mais de 16 caracteres';

    if (
      senha.length < quantidadeMinimaDeCaracteres ||
      !this.contemNumero(senha) ||
      !this.contemCaractere(senha)
    )
      return 'A senha não atende as exigências mínimas';
    console.log(senha);
  };

  validarConfirmacaoDeSenha = (confirmacaoDeSenha) => {
    const { senha } = this.state;
    if (senha !== confirmacaoDeSenha) return 'As senhas não são iguais';
  };
  onChange = (evento) => {
    const { name, value } = evento.target;

    this.setState({
      [name]: value
    });

    this.removerErro(name);
  };

  onBlur = (evento) => {
    const { name, value } = evento.target;
    const { erros } = this.state;
    const erro =
      name == 'senha'
        ? this.validarSenha(value)
        : this.validarConfirmacaoDeSenha(value);

    if (erro)
      this.setState({
        erros: { ...erros, [name]: erro }
      });
  };

  onFocus = (evento) => this.removerErro(evento.target.name);

  removerErro = (campo) => {
    const erros = { ...this.state.erros };
    delete erros[campo];
    this.setState({ erros });
  };

  validarFormulario = () => {
    const { senha, confirmacaoDeSenha } = this.state;

    const erroNaSenha = this.validarSenha(senha);
    const erroNaConfirmacaoDeSenha =
      this.validarConfirmacaoDeSenha(confirmacaoDeSenha);

    this.setState({
      erros: {
        senha: erroNaSenha,
        confirmacaoDeSenha: erroNaConfirmacaoDeSenha
      }
    });

    if (erroNaSenha || erroNaConfirmacaoDeSenha) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos em vermelho'
      });
      return false;
    }

    return true;
  };

  definirSenha = async (event) => {
    event.preventDefault();

    if (!this.validarFormulario()) {
      return;
    }

    try {
      const { location } = this.props;
      if (!location.state) {
        throw new Error('Dados da localização não disponíveis.');
      }

      const { codigo, cpf } = location.state;

      const response = await apiDaInscricao().post(
        `/inscricao/${cpf}/senhaDeAcessoDoTitular`,
        {
          codigoDeConfirmacao: codigo,
          senha: this.state.senha
        }
      );
      localStorage.setItem('senhaCriadaComSucesso', 'true');
      this.setState({ senhaCriadaComSucesso: true });
      this.props.endAjaxCall();
    } catch (e) {
      const erroApi =
        e.response?.data?.mensagens?.[0] ||
        'Não foi possível definir a senha. Tente novamente ou entre em contato com um posto de atendimento da AGEHAB';
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: erroApi
      });
    }
  };

  notificarCapsLockAtivo = (estaAtivo) => {
    this.setState({
      capsLockEstaAtivo: estaAtivo
    });
  };
  voltarParaLogin = () => {
    localStorage.removeItem('senhaCriadaComSucesso');
    this.props.history.push('/login');
  };

  render() {
    const { confirmacaoDeSenha, senha, erros, capsLockEstaAtivo, regras } =
      this.state;
    const inputEvents = {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus
    };

    console.log(senha.contemNumero);
    return (
      <div>
        <div className="header-senhaLogo">
          <dgx-brand name="habix" size="3" inverse="false" />
        </div>
        <div className="pagina-definicao">
          <div className="pagina-definicao-senha">
            {this.state.senhaCriadaComSucesso && (
              <div>
                <div className="pagina-definicao-codigo-icone-container">
                  <div className="pagina-definicao-codigo-icone">
                    <dgx-icon name="checkListCheckmark2" size="3" />
                  </div>
                </div>
                <div className="pagina-definicao-senha-header">
                  <dgx-heading
                    text="Senha criada!"
                    color="default"
                    size="6"
                    weight="medium"
                    as="h1"
                  />
                  <dgx-text
                    text="Tudo certo! Sua senha foi criada com sucesso. Clique no botão abaixo para ir para a tela de login."
                    color="neutral"
                    size="2"
                    weight="regular"
                    as="p"
                  />
                </div>
                <div className="pagina-confirmacao-codigo-button">
                  <dgx-button
                    text="Voltar para o login"
                    variant="surface"
                    color="neutral"
                    full="true"
                    onClick={this.voltarParaLogin}
                    type="button"
                  ></dgx-button>
                </div>
              </div>
            )}
            <div>
              {!this.state.senhaCriadaComSucesso && (
                <div className="pagina-definicao-senha-header">
                  <dgx-heading
                    id="foco-inicial-do-teclado"
                    text="Criar senha de acesso"
                    color="default"
                    size="6"
                    weight="medium"
                    as="h1"
                  />
                </div>
              )}
            </div>
            <div className="pagina-definicao-input">
              {!this.state.senhaCriadaComSucesso && (
                <form>
                  <dgx-textfield
                    placeholder="Digite uma nova senha"
                    variant="classic"
                    size="3"
                    disabled="false"
                    label="Nova senha"
                    name="senha"
                    ref={this.inputSenhaRef}
                    type="password"
                  />
                  <ValidadorDeSenha senha={this.state.senha} />
                  <dgx-textfield
                    ref={this.inputConfirmeSenhaRef}
                    placeholder="Confirmar nova senha"
                    variant="classic"
                    size="3"
                    label="Confirmar nova senha"
                    name="confirmacaoDeSenha"
                    type="password"
                  />

                  <div className="pagina-definicao-button">
                    <dgx-button
                      text="Salvar nova senha"
                      variant="solid"
                      color="accent"
                      size="3"
                      full="true"
                      type="button"
                      onClick={(event) => this.definirSenha(event)}
                    />
                  </div>
                </form>
              )}
              <SuporteModal />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { endAjaxCall })(DefinirSenha);
