import React from 'react';
//@ts-ignore
import Select from 'react-select';
import CampoDeTexto from '../../../../componentes/CampoDeTexto';
import CampoDeTextoComMascara from '../../../../componentes/CampoDeTextoComMascara';
import CampoDeficiencia from '../../../../componentes/CampoDeficiencia';
import CampoDoencaCronica from '../../../../componentes/CampoDoencaCronica';
import CampoSexo from '../../../../componentes/CampoSexo';
import { CampoDeValorEmReais, MensagemDeErro } from '../../../../componentes';
import ContainerRodape from '../../../../componentes/ContainerRodape';
import grausDeParentesco from './grausDeParentesco';
import Section from '../../../../componentes/Section';
import IndicadorPasso from '../../../../componentes/IndicadorPasso';
import { mapearProfissoesParaSelect } from '../../../../selectors/profissoes';
import situacoesNoMercadoDeTrabalho from './situacoesNoMercadoDeTrabalho';
import ICid from '../../../../interfaces/ICid';
import IDependente from '../../../../interfaces/IDependente';
import IProfissao from '../../../../interfaces/IProfissao';
import { IFormularioDependenteValidacaoErroCampos } from '../../../../interfaces/IFormularioDependenteValidacao';
import IGrauDeInstrucao from '../../../../interfaces/IGrauDeInstrucao';
import { mapearGrauDeInstrucaoParaSelect } from '../../../../selectors/grauDeInstrucoes';
import IDeficiencia from '../../../../interfaces/IDeficiencia';
import ITipoDeDeficiencia from '../../../../interfaces/ITipoDeDeficiencia';

interface IEventosDeficienciaProps {
    buscarCid: (cid: string) => void,
    adicionarDeficiencia: (deficiencia: IDeficiencia) => void,
    removerDeficiencia: (cid: ICid) => void,
}
interface IEventosDoencaCronica {
    buscarCid: (cid: string) => void,
    atualizarDoencaCronica: (tipoDeDoenca: string, doencaCronica: number, nomeDaDoencaCronica: string) => void,
    removerDoencaCronica: (tipoDeDoenca: string) => void,
}

interface IEventOnChange {
    target: { name: string, value?: string }
}

interface FormularioProps {
    dependente: IDependente,
    erros?: IFormularioDependenteValidacaoErroCampos,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onSave: () => void,
    eventosDeficiencia: IEventosDeficienciaProps,
    eventosDoencaCronica: IEventosDoencaCronica,
    onChangeNaoPossuiDeficiencia: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeNaoPossuiDoencaCronica: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeProfissao: (profissao?: IProfissao) => void,
    onChangeGrauDeInstrucao: (grauDeInstrucao?: IGrauDeInstrucao) => void,
    possuiDeficiencia?: boolean,
    possuiDoencaCronica?: boolean,
    profissoes: IProfissao[],
    grauDeInstrucoes: IGrauDeInstrucao[],
    onChangeSituacaoNoMercadoDeTrabalho: (event: React.ChangeEvent<HTMLInputElement>) => void,
    tiposDeDeficiencia: ITipoDeDeficiencia[]    
}

const Formulario = ({ dependente, erros, onChange, onBlur, onFocus, onSave, eventosDeficiencia, eventosDoencaCronica, onChangeNaoPossuiDeficiencia,
  onChangeNaoPossuiDoencaCronica, possuiDeficiencia, possuiDoencaCronica, profissoes, grauDeInstrucoes, onChangeProfissao, onChangeGrauDeInstrucao, onChangeSituacaoNoMercadoDeTrabalho, tiposDeDeficiencia }: FormularioProps) => {

  const inputEvents = { onChange, onBlur, onFocus };

  const onChangeDeficiencia = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = event.target;
    if (value == 'true') {
      event.target.value = 'true';
      onChange(event);
    } else {
      event.target.value = 'false';
      onChange(event);
      onChangeNaoPossuiDeficiencia(event);
    }
  };

  const onChangeDoencaCronica = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    const { value } = event.target;
    if (value == 'true') {
      event.target.value = 'true';
      onChange(event);
    } else {
      event.target.value = 'false';
      onChange(event);
      onChangeNaoPossuiDoencaCronica(event);
    }
  };

  const handleOnChangeGrauDeParentesco = (selected: { label: string, value: string }) => {
    const target: IEventOnChange = {
      target: {
        name: 'grauDeParentesco',
        value: !!selected ? selected.value : undefined
      }
    };
    //@ts-ignore
    inputEvents.onChange(target);
  };

  const handleOnChangeGrauDeInstrucao = (selected: { label: string, value: string }) => {
    if (selected) {
      const grauDeInstrucao: IGrauDeInstrucao = { id: parseInt(selected.value), nome: selected.label };
      onChangeGrauDeInstrucao(grauDeInstrucao);
    } else
      onChangeGrauDeInstrucao(selected);
  };

  const handleOnChangeSituacaoNoMercadoDeTrabalho = (selected: { label: string, value: string }) => {
    const target: IEventOnChange = {
      target: {
        name: 'situacaoNoMercadoDeTrabalho',
        value: !!selected ? selected.value : undefined
      }
    };
    //@ts-ignore
    onChangeSituacaoNoMercadoDeTrabalho(target);
  };

  const handleOnChangeProfissao = (selected: { label: string, value: string }) => {
    if (selected) {
      const profissao: IProfissao = { id: parseInt(selected.value), nome: selected.label };
      onChangeProfissao(profissao);
    } else
      onChangeProfissao(selected);
  };

  return (
    <form className="formulario formulario_medio">
      <Section comMargemSuperiorMedia={false}>
        <IndicadorPasso
          iconeDoPasso='fa-users'
          titulo='Dependentes'
          descricao='Dependentes são todos aqueles que vivem na casa do(a) pretendente. Exemplo: filhos menores de 18 anos.' />
        <hr />
        <div className="grade__linha">
          <div className="grade__coluna">
            <h2 className="titulo titulo_medio texto_cor-secundaria">
              <strong>Dados Pessoais</strong>
            </h2>
          </div>
        </div>
        <CampoDeTexto
          label="Nome completo"
          name="nome"
          value={dependente.nome || ''}
          maxLength={150}
          erro={erros?.nome ? 'Campo obrigatório' : ''}
          {...inputEvents} />
        <CampoSexo
          sexo={dependente.sexo}
          error={!!erros?.sexo}
          onChangeSexo={onChange} disabled={false} />
        <hr />
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <div className="grade__coluna u-largura-total">
            <div className={`formulario__input-react-select ${erros?.grauDeParentesco && 'formulario__input-react-select_erro'}`}>
              <label className="formulario__label">
                Grau de parentesco
              </label>
              {erros?.grauDeParentesco && <MensagemDeErro campo='grauDeParentesco' />}
              <Select
                name="grauDeParentesco"
                id="grauDeParentesco"
                placeholder="Selecione uma opção"
                value={dependente.grauDeParentesco}
                options={grausDeParentesco}
                onChange={handleOnChangeGrauDeParentesco} />
            </div>
          </div>
          <CampoDeTextoComMascara
            colunas='6'
            label="Data de nascimento"
            name="dataDeNascimento"
            mascara="99/99/9999"
            pattern="\d*"
            value={dependente.dataDeNascimento?.toString() || ''}
            erro={erros?.dataDeNascimento ? 'Campo obrigatório' : ''}
            {...inputEvents}
          />
        </div>
        <hr />
        <CampoDeTextoComMascara
          label="NIS - Número de Identificação Social"
          name="nis"
          mascara="999.99999.99-9"
          value={dependente.nis || ''}
          pattern="\d*"
          required={false}
          erro={erros?.nis ? 'Nis inválido' : ''}
          {...inputEvents} >
          <div className="u-margem-inferior-pequena">
            O NIS pode ser encontrado no Cartão Cidadão ou no cartão do Bolsa Família. Para determinados programas habitacionais,
            como o Minha Casa Minha Vida - Faixa 1, o NIS é obrigatório.
          </div>
          <div>
            Caso não possua, procure a Secretaria Municipal de Assistência Social ou o Centro de Referência de Assistência Social do seu município.
          </div>
        </CampoDeTextoComMascara>
      </Section>
      <Section>
        <div className="grade__linha">
          <div className="grade__coluna">
            <h2 className="titulo titulo_medio texto_cor-secundaria">
              <strong>Emprego e renda</strong>
            </h2>
          </div>
        </div>
        <hr />

        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <div className="grade__coluna grade__coluna_6">
            <div className={`formulario__input-react-select ${erros?.grauDeInstrucao && 'formulario__input-react-select_erro'}`}>
              <label className="formulario__label">
                Grau de instrução
              </label>
              {erros?.grauDeInstrucao && <MensagemDeErro campo='grauDeInstrucao' />}
              <Select
                name="grauDeInstrucao"
                id="grauDeInstrucao"
                placeholder="Selecione uma opção"
                value={dependente.grauDeInstrucao?.id}
                options={mapearGrauDeInstrucaoParaSelect(grauDeInstrucoes)}
                onChange={handleOnChangeGrauDeInstrucao} />
            </div>
          </div>
          <div className="grade__coluna grade__coluna_6">
            <div className={`formulario__input-react-select ${erros?.situacaoNoMercadoDeTrabalho && 'formulario__input-react-select_erro'}`}>
              <label className="formulario__label">
                Ocupação no mercado de trabalho
              </label>
              {erros?.situacaoNoMercadoDeTrabalho && <MensagemDeErro campo='situacaoNoMercadoDeTrabalho' />}
              <Select
                name="situacaoNoMercadoDeTrabalho"
                id="situacaoNoMercadoDeTrabalho"
                placeholder="Selecione uma opção"
                value={dependente.situacaoNoMercadoDeTrabalho}
                options={situacoesNoMercadoDeTrabalho}
                onChange={handleOnChangeSituacaoNoMercadoDeTrabalho} />
            </div>
          </div>
        </div>
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <div className="grade__coluna grade__coluna_6">
            <div className={`formulario__input-react-select ${erros?.profissao && 'formulario__input-react-select_erro'}`}>
              <label className="formulario__label">
                Profissão
              </label>
              {erros?.profissao && <MensagemDeErro campo='profissao' />}
              <Select
                name="profissao"
                id="profissao"
                placeholder="Selecione uma opção"
                value={dependente.profissao?.id}
                options={mapearProfissoesParaSelect(profissoes)}
                onChange={handleOnChangeProfissao} />
            </div>
          </div>
          {dependente.situacaoNoMercadoDeTrabalho != 'Desempregado' &&
          <div className="grade__coluna grade__coluna_6">
            <div className={`formulario__input-react-select ${erros?.valorDaRenda && 'formulario__input-react-select_erro'}`}>
              <CampoDeValorEmReais
                disabled={false}
                label="Renda do Dependente"
                descricao="Esta renda não pode ser benefício temporário como bolsa família, loas, mais social e outros."
                name="valorDaRenda"
                required={false}
                value={dependente.valorDaRenda || 0}
                erro={erros?.valorDaRenda ? 'Campo obrigatório' : ''}
                {...inputEvents} />
            </div>
          </div>
          }
        </div>
      </Section>
      <Section>
        <div className="grade__linha">
          <div className="grade__coluna">
            <h2 className="titulo titulo_medio texto_cor-secundaria">
              <strong>Saúde</strong>
            </h2>
          </div>
        </div>
        <hr />

        <CampoDeficiencia
          possuiDeficiencia={possuiDeficiencia != null ? possuiDeficiencia : !!dependente.deficiencias && dependente.deficiencias.length > 0}
          deficiencias={dependente.deficiencias}
          erroDeficiencias={erros?.deficiencias}
          onChangePossuiDeficiencia={onChangeDeficiencia}
          eventosDeficiencia={eventosDeficiencia}
          perguntaPossuiDeficiencia='O Dependente possui alguma deficiência?'
          afirmacaoPossuiDeficiencia='Sim, o dependente possui deficiência e tem atestado'
          disabled={false} 
          tiposDeDeficiencia={tiposDeDeficiencia} 
        />
        <CampoDoencaCronica
          possuiDoencaCronica={possuiDoencaCronica != null ? possuiDoencaCronica : !!dependente.cidDaDoencaCronica}
          doencaCronica={dependente.cidDaDoencaCronica?.codigo || ''}
          nomeDaDoencaCronica={dependente.cidDaDoencaCronica ? dependente.cidDaDoencaCronica.codigo + ' - ' + dependente.cidDaDoencaCronica.descricao : ''}
          onChangePossuiDoencaCronica={onChangeDoencaCronica}
          eventosDoencaCronica={eventosDoencaCronica}
          erroDoencaCronica={erros?.doencaCronica}
          perguntaPossuiDoencaCronica='O Dependente possui alguma doença crônica incapacitante para o trabalho?'
          afirmacaoPossuiDoencaCronica='Sim, o dependente possui doença crônica e tem atestado'
          disabled={false} 
          tituloTipoDeDoenca='Doença Crônica incapacitante para o trabalho'
          tipoDeDoenca='' />
        <ContainerRodape>
          <div className="grade__coluna">
            <button type="button"
              className="botao botao_medio botao_cor-secundaria botao_largura-total"
              onClick={onSave}>
              Avançar</button>
          </div>
        </ContainerRodape>
      </Section>
    </form >
  );
};

export default Formulario;