import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiDaInscricao } from '../../../servicos/axios';
import { manterSomenteNumeros } from '../../../helpers/mascaras';
import { endAjaxCall } from '../../../actions/requisicoesAjax.actions';
import { consultarExistenciaDeInscricao } from '../../../servicos/inscricao';
import { HeaderSenhaLogo, SuporteModal } from '../../../componentes';
class RedefinirSenha extends Component {
  constructor(props) {
    super(props);
    this.inputCpfRef = React.createRef();
    this.state = {
      cpf: ''
    };
  }

  componentDidMount() {
    if (this.inputCpfRef.current) {
      this.inputCpfRef.current.onTextFieldChange = this.atualizarCpf;
    }
  }

  atualizarCpf = (event) => {
    const { value } = event.target;

    this.setState({
      cpf: value,
      mensagemErroNoCampoCpf: ''
    });
  };

  validarSeCpfPossuiOnzeDigitos = (cpf) => {
    console.log('Validando CPF:', cpf);
    if (cpf.length !== 11) {
      this.setState({
        mensagemErroNoCampoCpf: 'CPF inválido'
      });
      return false;
    }
    return true;
  };

  obterTelefoneCelularDoTitular = async (event) => {
    event.preventDefault();

    let cpfApenasComNumeros = manterSomenteNumeros(this.state.cpf);

    var cpfPossuiOnzeDigitos =
      this.validarSeCpfPossuiOnzeDigitos(cpfApenasComNumeros);
    if (!cpfPossuiOnzeDigitos) return;

    try {
      await consultarExistenciaDeInscricao(
        cpfApenasComNumeros,
        this.props.dispatch
      );

      var respostaDaRequisicao = await apiDaInscricao().get(
        `/titulares/${cpfApenasComNumeros}/contatos`
      );
      const telefoneCelularComDigitosOcultos =
        respostaDaRequisicao.data['telefoneCelular'];
      const emailComCaracteresOcultos = respostaDaRequisicao.data['email'];
      const tipoDeContato = respostaDaRequisicao.data['tipoDeContato'];

      this.props.endAjaxCall();

      this.setState({
        telefoneCelularComDigitosOcultos,
        emailComCaracteresOcultos,
        tipoDeContato,
        mensagemErroDoContato: ''
      });

      this.props.history.push('/redefinir-senha/envio-de-codigo', {
        cpf: this.state.cpf,
        telefoneCelularComDigitosOcultos,
        emailComCaracteresOcultos,
        tipoDeContato
      });
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.setState({
          mensagemErroDoContato: 'Não localizamos nenhuma inscrição neste CPF.'
        });
      } else {
        this.setState({
          mensagemErroDoContato: 'Não localizamos nenhuma inscrição neste CPF.'
        });
      }

      this.props.endAjaxCall();
    }
  };

  render() {
    const { location, history } = this.props;
    const esqueceuASenha =
      location && location.state && location.state.esqueceuASenha;
    const inputEvents = {
      onChange: this.atualizarCpf,
      onFocus: this.limparMensagemDeErroDeTamanhoDoCpf
    };
    const mensagemErroDoContato = this.state.mensagemErroDoContato;

    return (
      <section className="pagina-esqueci-senha">
        <HeaderSenhaLogo />
        <div className="pagina-esqueci-senha-conteudo">
          <div className="pagina-esqueci-senha-container">
            <div className="pagina-esqueci-senha-texto">
              <dgx-heading
                id="foco-inicial-do-teclado"
                text={esqueceuASenha ? 'Esqueci a senha' : 'Criar uma senha'}
                color="default"
                size="6"
                weight="medium"
                as="h1"
              ></dgx-heading>
              <dgx-text
                text="Para localizarmos a sua inscrição, digite seu CPF abaixo"
                color="neutral"
                size="2"
                weight="regular"
                as="p"
              ></dgx-text>
            </div>
            <div className="pagina-esqueci-senha-input">
              <form>
                {mensagemErroDoContato && (
                  <div className="pagina-esqueci-senha-input-error">
                    <dgx-callout
                      size="2"
                      variant="surface"
                      icon-name="infoInformation"
                      color="error"
                      message={mensagemErroDoContato}
                    ></dgx-callout>
                  </div>
                )}
                <dgx-textfield
                  ref={this.inputCpfRef}
                  placeholder="Digite o seu CPF"
                  name="cpf"
                  size="3"
                  type="cpf"
                  label="Seu CPF"
                ></dgx-textfield>
                <dgx-button
                  text="Avançar"
                  variant="solid"
                  color="accent"
                  size="3"
                  full="true"
                  type="button"
                  onClick={this.obterTelefoneCelularDoTitular}
                ></dgx-button>
              </form>
              <div className="pagina-esqueci-senha-links">
                <dgx-text
                  text="Lembrou que já possui senha?"
                  color="default"
                  size="2"
                  weight="medium"
                  as="p"
                ></dgx-text>
                <dgx-text
                  text="Faça o login"
                  color="accent"
                  size="2"
                  weight="medium"
                  as="a"
                  href="/login "
                  onClick={() => history.push('login')}
                ></dgx-text>
              </div>
            </div>
          </div>
        </div>
        <SuporteModal />
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    endAjaxCall: () => dispatch(endAjaxCall())
  };
};

export default connect(null, mapDispatchToProps)(RedefinirSenha);
