import { dataValida } from '../../../../helpers/verificadorDeData';
import validadorDeNis from '../../../../helpers/validadorDeNis';
import IDependente from '../../../../interfaces/IDependente';
import IDeficiencia from '../../../../interfaces/IDeficiencia';
import IDependenteValidacaoCampos from '../../../../interfaces/IFormularioDependenteValidacao';

let _dadosDependente: IDependente;
let _possuiDeficiencia: boolean | undefined;
let _possuiDoencaCronica: boolean | undefined;

const CAMPO_OBRIGATORIO = 'Campo obrigatório';

const obrigatorio = (valor?: string) => {
  if (!valor)
    return CAMPO_OBRIGATORIO;
};

const nome = (nome: string) => {
  const regex = /^[a-záàâãéèêíïóôõöúçñ ]+$/i;
  if (!!nome && !regex.test(nome))
    return 'Não é permitido a entrada de números ou caracteres especiais';
};

function obrigatorioSeInformouPossuirAlgumaDeficiencia() {
  if (_possuiDeficiencia && (!_dadosDependente.deficiencias?.length || _dadosDependente.deficiencias?.some((d: IDeficiencia) => !d.cid)))
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiDoencaCronica() {
  if (_possuiDoencaCronica && !_dadosDependente.cidDaDoencaCronica)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeNaoDesempregadoEMaior18Anos(valor: string) {
  if ((!valor || valor === 'R$ 0,00') && (!_dadosDependente.dataDeNascimento || dependenteEhMaiorDeIdade(_dadosDependente.dataDeNascimento))
        && (_dadosDependente.situacaoNoMercadoDeTrabalho !== 'Desempregado' && _dadosDependente.situacaoNoMercadoDeTrabalho !== 'NaoSeAplica')
  )
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSerValidoSePossuirNis() {
  const { nis } = _dadosDependente;
  if (!!nis && !validadorDeNis.validarNis(nis))
    return 'Nis inválido';
}

const dependenteEhMaiorDeIdade = (dataDeNascimento: Date) => {
  let dataAtual = new Date();
  let anoAtual = dataAtual.getFullYear();
  let mesAtual = dataAtual.getMonth() + 1;
  let diaAtual = dataAtual.getDate();

  if (dataDeNascimento == null)
    return false;

  let dataDeNascimentoEmPartes = dataDeNascimento.toString().split('/');

  let dataDeNascimentoDoDependente = new Date(
    //@ts-ignore
    dataDeNascimentoEmPartes[2],
    //@ts-ignore
    dataDeNascimentoEmPartes[1] - 1,
    //@ts-ignore
    dataDeNascimentoEmPartes[0]
  );

  let anoAniversario = dataDeNascimentoDoDependente.getFullYear();
  let mesAniversario = dataDeNascimentoDoDependente.getMonth() + 1;
  let diaAniversario = dataDeNascimentoDoDependente.getDate();

  let idade = anoAtual - anoAniversario;

  if (
    mesAtual < mesAniversario ||
    (mesAtual == mesAniversario && diaAtual < diaAniversario)
  ) {
    idade--;
  }

  return idade > 18;
};

const profissaoObrigatorioSeMaiorDe18Anos = () => {
  if (!_dadosDependente.profissao &&
    (!_dadosDependente.dataDeNascimento || dependenteEhMaiorDeIdade(_dadosDependente.dataDeNascimento))) {
    return CAMPO_OBRIGATORIO;
  }
};

const situacaoNoMercadoDeTrabalhoObrigatorioSeMaiorDe18Anos = () => {
  if (!_dadosDependente.situacaoNoMercadoDeTrabalho &&
     (!_dadosDependente.dataDeNascimento || dependenteEhMaiorDeIdade(_dadosDependente.dataDeNascimento))) {
    return CAMPO_OBRIGATORIO;
  }
};

const validacoes: IDependenteValidacaoCampos = {
  nome: [obrigatorio, nome],
  sexo: [obrigatorio],
  grauDeParentesco: [obrigatorio],
  situacaoNoMercadoDeTrabalho: [situacaoNoMercadoDeTrabalhoObrigatorioSeMaiorDe18Anos],
  profissao: [profissaoObrigatorioSeMaiorDe18Anos],
  valorDaRenda: [obrigatorioSeNaoDesempregadoEMaior18Anos],
  nis: [obrigatorioSerValidoSePossuirNis],
  dataDeNascimento: [obrigatorio, dataValida],
  deficiencias: [obrigatorioSeInformouPossuirAlgumaDeficiencia],
  doencaCronica: [obrigatorioSePossuiDoencaCronica],
  grauDeInstrucao: [obrigatorio],
  possuiDeficiencia: [],
  possuiDoencaCronica: []
};

const aplicarFuncoesDeValidacao = (contexto: IDependente, campo: string, valor: string, possuiDeficiencia?: boolean, possuiDoencaCronica?: boolean) => {
  //@ts-ignore
  const funcoes = validacoes[campo] || [];

  _dadosDependente = contexto;
  _possuiDeficiencia = possuiDeficiencia;
  _possuiDoencaCronica = possuiDoencaCronica;
  const retornosComErro = funcoes.map((f: Function) => f.call(contexto, valor)).filter((r: any) => !!r);
  return retornosComErro[0];
};

const validarFormulario = (dadosDependente: IDependente, possuiDeficiencia?: boolean, possuiDoencaCronica?: boolean) => {

  const errosValidacao: any = Object.keys(validacoes).reduce((erros, campo) => {
    //@ts-ignore
    const erro = aplicarFuncoesDeValidacao(dadosDependente, campo, dadosDependente[campo], possuiDeficiencia, possuiDoencaCronica);
    if (erro)
      return { ...erros, [campo]: erro };
    return erros;
  }, {});
  return errosValidacao;
};
export default { validarCampo: aplicarFuncoesDeValidacao, validarFormulario };