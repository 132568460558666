import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../../estilos/estilos.scss';
import { ReactComponent as LogosDoSistema } from '../../componentes/Logos/logo-governo-e-agehab.svg';
import { logoff } from '../../servicos/autenticacao';

import iconWarning from './imagens/warning-icon.svg.svg';

import {
  verificarSeAceitouOsTermosDeUso,
  redirecionarParaAceitacaoDeTermosDeUso
} from '../../servicos/termoDeUso';
import {
  endAjaxCall,
  beginAjaxCall
} from '../../actions/requisicoesAjax.actions';
import { connect } from 'react-redux';

class AvisoDocumentos extends Component {
  componentDidMount() {
    if (this.props.location.state)
      this.verificarAceitacaoDosTermosDeUso(this.props.location.state);
  }

  verificarAceitacaoDosTermosDeUso = async ({
    cpfDoTitular,
    dadosDoCadastro
  }) => {
    if (!cpfDoTitular || !dadosDoCadastro) {
      logoff();
      this.props.endAjaxCall();
    }

    this.props.beginAjaxCall();
    const aceitouOsTermosDeUso =
      await this.props.verificarSeAceitouOsTermosDeUso(cpfDoTitular);
    if (!aceitouOsTermosDeUso) {
      this.props.redirecionarParaAceitacaoDeTermosDeUso(
        dadosDoCadastro,
        '/aviso-documentos'
      );
      return;
    }
    this.props.endAjaxCall();
  };

  voltar = () => {
    logoff();
    this.props.history.goBack();
  };

  listaDocumentos = [
    {
      label: 'RG (Registro Geral)'
    },
    {
      label: 'CPF (Cadastro de Pessoa Física)'
    },
    {
      label: 'Comprovante de estadoComprovante de estado civil'
    },
    {
      label: 'Certidões (nascimento, casamento e filhos - se houver)'
    },
    {
      label: 'Carteira de trabalho'
    },
    {
      label: 'NIS (Número de Identificação Social), caso tiver;'
    },
    {
      label: 'Holerite, caso tenha vínculo empregatício'
    },
    {
      label: 'CIE (Célula de Identidade de Estrangeiro), caso seja estrangeiro;'
    },
    {
      label:
        'Código de CID, encontrado no atestado médico, para casos de deficiências e/ou doenças crônicas incapacitantes permanentes.'
    }
  ];

  render() {
    return (
      <section className="aviso-de-documentos_new">
        <div className='aviso-de-documentos_new-bg'>
          
        </div>
        <div className="aviso-de-documentos_new-conteudo">
          <div className="aviso-de-documentos_new-conteudo-wrapper">
            <div className="aviso-de-documentos_new-conteudo-texto">
              <img src={iconWarning} alt="ícone de aviso" />
              <dgx-text
                text="Fique atento aos documentos"
                color="default"
                size="4"
                weight="regular"
                as="p"
              ></dgx-text>
              <dgx-text
                text="Ao prosseguir será obrigatórios alguns documentos. Tenha-os em mãos para facilitar o preenchimento da sua inscrição."
                color="neutral"
                size="3"
                weight="regular"
                as="p"
              ></dgx-text>
            </div>
            <dgx-separator orientation="horizontal" size="full"></dgx-separator>
            <div className="aviso-de-documentos_new-conteudo-lista">
              <ul className="aviso-de-documentos_new-conteudo-lista-items">
                {this.listaDocumentos.map((item) => (
                  <li>
                    <dgx-text
                      text={item.label}
                      color="default"
                      size="3"
                      weight="regular"
                      as="p"
                    ></dgx-text>
                  </li>
                ))}
              </ul>
            </div>
            <div className="aviso-de-documentos_new-conteudo-button">
              <dgx-button
                text="Voltar"
                variant="surface"
                full="true"
                onClick={() => this.voltar()}
              ></dgx-button>
              <dgx-button
                text="Avançar"
                full="true"
                onClick={() => this.props.history.push('/dados-titular')}
              ></dgx-button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verificarSeAceitouOsTermosDeUso: (cpfDoTitular) =>
      verificarSeAceitouOsTermosDeUso(cpfDoTitular, dispatch),
    redirecionarParaAceitacaoDeTermosDeUso: (
      dadosDoCadastro,
      pathAdicionalDeRetorno
    ) =>
      redirecionarParaAceitacaoDeTermosDeUso(
        dadosDoCadastro,
        dispatch,
        pathAdicionalDeRetorno
      ),
    beginAjaxCall: () => dispatch(beginAjaxCall()),
    endAjaxCall: () => dispatch(endAjaxCall())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AvisoDocumentos));
