import React from 'react';

interface InformacoesDeEnderecoProps {
  endereco: string;
  descricao: string;
  info: string;
}

const InformacoesDeEndereco = ({
  endereco,
  descricao,
  info,
}: InformacoesDeEnderecoProps) => {
  return (
    <div className="InformacoesDeEndereco">
      <dgx-icon name="markerPin" size="3"></dgx-icon>
      <div className='InformacoesDeEndereco-group'>
        <dgx-text
          text={endereco}
          color="inverse"
          size="1"
          weight="medium"
        ></dgx-text>
        <dgx-text
          text={descricao}
          color="inverse"
          size="1"
          weight="light" 
        ></dgx-text>
        <dgx-text
          text={info}
          color="inverse"
          size="1"
          weight="regular" 
        ></dgx-text>
      </div>
    </div>
  );
};

export default InformacoesDeEndereco;
