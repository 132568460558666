import React, { Component } from 'react';
import mascaras from '../../../helpers/mascaras';
import { Redirect } from 'react-router-dom';
import { apiDaInscricao } from '../../../servicos/axios';
import { connect } from 'react-redux';
import { endAjaxCall } from '../../../actions/requisicoesAjax.actions';
import { HeaderSenhaLogo, SuporteModal } from '../../../componentes';

class EnvioDeCodigo extends Component {
  constructor(props) {
    super(props);
    this.radioEmailRef = React.createRef();
    this.radioSmsRef = React.createRef();

    this.state = {
      mensagemDeErroDoEnvioDaConfirmacao: false
    };
    this.chaveDeControleDoEnvioDeCodigo = 'envio-de-codigo';
    this.alterarTipoDeContatoParaConfirmacao =
      this.alterarTipoDeContatoParaConfirmacao.bind(this);
    this.mensagemDeTipoDeContatoParaRecuperacaoDeSenha = {
      Sms: (
        <>
          Enviaremos um '<strong>SMS</strong>' para o telefone abaixo:
        </>
      ),
      Email: 'Enviaremos um código de recuperação para o e-mail abaixo:',
      Ambos: '',
      Nenhum: (
        <>
          Não encontramos nenhum número de <strong>celular ou e-mail</strong>{' '}
          para contato na inscrição referente ao CPF:{' '}
          <strong>{this.obterCpf()}</strong>
        </>
      )
    };
  }

  componentDidMount() {
    const propsRecebidos = this.props.location.state || {};

    if (this.radioEmailRef.current) {
      this.radioEmailRef.current.addEventListener(
        'radioChanged',
        this.alterarTipoDeContatoParaConfirmacao
      );
    }

    if (this.radioSmsRef.current) {
      this.radioSmsRef.current.addEventListener(
        'radioChanged',
        this.alterarTipoDeContatoParaConfirmacao
      );
    }

    if (
      propsRecebidos.emailComCaracteresOcultos &&
      !propsRecebidos.telefoneCelularComDigitosOcultos
    )
      this.setState({ tipoDeContatoParaConfirmacao: 'email' });
    else if (
      propsRecebidos.telefoneCelularComDigitosOcultos &&
      !propsRecebidos.emailComCaracteresOcultos
    ) {
      this.setState({ tipoDeContatoParaConfirmacao: 'sms' });
    }

    document.querySelector('#foco-inicial-do-teclado').focus();
  }

  componentWillUnmount() {
    if (this.radioEmailRef.current) {
      this.radioEmailRef.current.removeEventListener(
        'radioChanged',
        this.alterarTipoDeContatoParaConfirmacao
      );
    }

    if (this.radioSmsRef.current) {
      this.radioSmsRef.current.removeEventListener(
        'radioChanged',
        this.alterarTipoDeContatoParaConfirmacao
      );
    }
  }

  obterCpf() {
    const propsRecebidos = this.props.location.state || {};
    return propsRecebidos.cpf;
  }

  obterSegundosDesdeUltimoEnvioDeCodigo = () => {
    const ultimaVezQueEnviouUmCodigo = localStorage.getItem(
      this.chaveDeControleDoEnvioDeCodigo
    );

    if (!ultimaVezQueEnviouUmCodigo) return null;

    const horarioAtual = new Date().getTime();
    const horarioDaUltimaGeracao = new Date(
      ultimaVezQueEnviouUmCodigo
    ).getTime();
    return Math.abs((horarioAtual - horarioDaUltimaGeracao) / 1000);
  };

  enviarCodigoDeConfirmacao = async (
    telefoneCelularComDigitosOcultos,
    emailComCaracteresOcultos,
    cpf,
    event
  ) => {
    event.preventDefault();
    let tipoDeContatoParaConfirmacao = this.state.tipoDeContatoParaConfirmacao;
    const segundosDesdeUltimoEnvio =
      this.obterSegundosDesdeUltimoEnvioDeCodigo();
    let cpfApenasComNumeros = mascaras.manterSomenteNumeros(cpf);
    try {
      let propsParaTelaDeConfirmacao = {
        telefoneCelularComDigitosOcultos,
        emailComCaracteresOcultos,
        tipoDeContatoParaConfirmacao,
        cpf
      };

      if (!segundosDesdeUltimoEnvio || segundosDesdeUltimoEnvio > 60) {
        propsParaTelaDeConfirmacao['deveMostrarMensagemFlutuante'] = true;
        await apiDaInscricao().post(
          `/titulares/${cpfApenasComNumeros}/solicitacaoDeCodigo/${tipoDeContatoParaConfirmacao}`
        );
        this.props.endAjaxCall();
        localStorage.setItem(this.chaveDeControleDoEnvioDeCodigo, new Date());
      } else {
        propsParaTelaDeConfirmacao['deveMostrarMensagemFlutuante'] = false;
      }

      this.props.history.push(
        '/redefinir-senha/confirmar-codigo',
        propsParaTelaDeConfirmacao
      );
    } catch (e) {
      if (!e.response || !e.response.data) {
        this.setState({
          mensagemDeErroDoEnvioDaConfirmacao: `Não foi possível enviar o código de confirmação no momento.
         Caso o problema persista entre em contato com o suporte.`
        });
        return;
      }
      let mensagemDeErroDaResposta =
        typeof e.response.data === 'string'
          ? e.response.data
          : e.response.data.mensagens[0];
      this.setState({
        mensagemDeErroDoEnvioDaConfirmacao: mensagemDeErroDaResposta
      });
    }
  };

  limparMensagemDeErro = () => {
    this.setState({
      mensagemDeErroDoEnvioDaConfirmacao: false
    });
  };

  alterarTipoDeContatoParaConfirmacao = (event) => {
    console.log(event.target.value);

    this.setState({ tipoDeContatoParaConfirmacao: event.target.value });
  };

  definirMensagemDeTipoDeContatoParaRecuperacaoDeSenha(tipoDeContato) {
    let mensagem =
      this.mensagemDeTipoDeContatoParaRecuperacaoDeSenha[tipoDeContato];
    return <p className="u-margem-inferior-pequena">{mensagem}</p>;
  }

  definirOpcaoTelefoneCelularParaRecuperacaoDeSenha(
    telefoneCelularComDigitosOcultos,
    tipoDeContato
  ) {
    if (telefoneCelularComDigitosOcultos) {
      if (tipoDeContato === 'Sms') {
        return (
          <div>
            <h3 className="titulo titulo-medio texto_cor-secundaria">SMS</h3>
            <p id="foco-inicial-do-teclado" tabIndex="-1" className="texto">
              {<strong>{telefoneCelularComDigitosOcultos}</strong>}
            </p>
          </div>
        );
      } else if (tipoDeContato !== 'Nenhum') {
        return (
          <>
            <dgx-radio
              ref={this.radioSmsRef}
              radio-id="sms"
              label="SMS"
              value="sms"
            />
            <div className="pagina-envio-codigo-text">
              <dgx-text
                text={`Receber o código de validação no endereço de telefone <strong>${telefoneCelularComDigitosOcultos}</strong>`}
                color="neutral"
                size="2"
                weight="regular"
                as="p"
              />
            </div>
          </>
        );
      }
    }
  }

  definirOpcaoEmailParaRecuperacaoDeSenha(
    emailComCaracteresOcultos,
    tipoDeContato
  ) {
    if (emailComCaracteresOcultos) {
      if (tipoDeContato === 'Email') {
        return (
          <div>
            <h3 className="titulo titulo-medio texto_cor-secundaria">Email</h3>
            <p id="foco-inicial-do-teclado" tabIndex="-1" className="texto">
              {<strong>{emailComCaracteresOcultos}</strong>}
            </p>
          </div>
        );
      } else if (tipoDeContato !== 'Nenhum') {
        return (
          <>
            <dgx-radio
              ref={this.radioEmailRef}
              radio-id="email"
              label="Email"
              value="email"
            />
            <div className="pagina-envio-codigo-text">
              <dgx-text
                text={`Receber o código de validação no endereço de email <strong>${emailComCaracteresOcultos}</strong>`}
                color="neutral"
                size="2"
                weight="regular"
                as="p"
              />
            </div>
          </>
        );
      }
    }
  }

  render() {
    const propsRecebidos = this.props.location.state || {};
    const { history } = this.props;
    let cpf = propsRecebidos.cpf;
    let telefoneCelularComDigitosOcultos =
      propsRecebidos.telefoneCelularComDigitosOcultos;
    let emailComCaracteresOcultos = propsRecebidos.emailComCaracteresOcultos;
    let tipoDeContato = propsRecebidos.tipoDeContato;

    return (
      <>
        <div
          mensagemDeErro={this.state.mensagemDeErroDoEnvioDaConfirmacao}
          limparMensagemDeErro={this.limparMensagemDeErro}
        >
          {!cpf && !telefoneCelularComDigitosOcultos && (
            // eslint-disable-next-line no-restricted-globals
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )}
          <HeaderSenhaLogo />
          <div className="pagina-envio">
            <div className="pagina-envio-codigo">
              <div>
                <div className="pagina-envio-codigo-header">
                  <dgx-heading
                    id="foco-inicial-do-teclado"
                    text="Criar uma senha"
                    color="default"
                    size="6"
                    weight="medium"
                    as="h1"
                  />
                  <dgx-text
                    text="Localizamos sua inscrição! Agora informe onde deseja receber o código para conseguir criar a sua senha."
                    color="neutral"
                    size="2"
                    weight="regular"
                    as="p"
                  />
                </div>
              </div>

              <div className="pagina-envio-codigo-radio">
                <form>
                  <div>
                    <dgx-radio-group>
                      {this.definirMensagemDeTipoDeContatoParaRecuperacaoDeSenha(
                        tipoDeContato
                      )}

                      {this.definirOpcaoEmailParaRecuperacaoDeSenha(
                        emailComCaracteresOcultos,
                        tipoDeContato
                      )}

                      {this.definirOpcaoTelefoneCelularParaRecuperacaoDeSenha(
                        telefoneCelularComDigitosOcultos,
                        tipoDeContato
                      )}
                    </dgx-radio-group>
                  </div>
                </form>
              </div>

              <div className="pagina-envio-codigo-rodape">
                <div className="pagina-envio-codigo-button">
                  <dgx-button
                    text="Avançar"
                    size="3"
                    type="button"
                    full="true"
                    onClick={(event) =>
                      this.enviarCodigoDeConfirmacao(
                        telefoneCelularComDigitosOcultos,
                        emailComCaracteresOcultos,
                        cpf,
                        event
                      )
                    }
                  />
                  {!(
                    telefoneCelularComDigitosOcultos ||
                    emailComCaracteresOcultos
                  ) && (
                    <dgx-button
                      text="Voltar para tela de início"
                      variant="solid"
                      color="neutral"
                      size="3"
                      full="true"
                      type="button"
                    ></dgx-button>
                  )}
                </div>
                <div className="pagina-envio-codigo-links">
                  <dgx-text
                    text="Lembrou da senha?"
                    color="default"
                    size="2"
                    weight="medium"
                    as="p"
                  ></dgx-text>
                  <dgx-text
                    text="Faça o login"
                    color="accent"
                    size="2"
                    weight="medium"
                    onClick={() => history.push('/login')}
                  ></dgx-text>
                </div>
                {(telefoneCelularComDigitosOcultos ||
                  emailComCaracteresOcultos) && (
                  <dgx-callout
                    size="1"
                    variant="surface"
                    icon-name="infoInformation"
                    color="warning"
                    message="Dados desatualizados"
                    description="Não reconhece estes dados ou estão desatualizados? Agende seu atendimento em uma de nossas unidades."
                  />
                )}

                {!(
                  telefoneCelularComDigitosOcultos || emailComCaracteresOcultos
                ) && (
                  <dgx-callout
                    size="1"
                    variant="surface"
                    icon-name="infoInformation"
                    color="warning"
                    message="Dados desatualizados"
                    description="Não reconhece estes dados ou estão desatualizados? Agende seu atendimento em uma de nossas unidades."
                  />
                )}
                <div className="pagina-envio-codigo-button">
                  {!(
                    telefoneCelularComDigitosOcultos ||
                    emailComCaracteresOcultos
                  ) && (
                    <dgx-button
                      text="Voltar para tela de início"
                      variant="solid"
                      color="neutral"
                      size="3"
                      disabled="false"
                      full="true"
                      type="button"
                    ></dgx-button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <SuporteModal />
        </div>
      </>
    );
  }
}

export default connect(null, { endAjaxCall })(EnvioDeCodigo);
{
  /* 
 
      <LayoutDaPaginaDeEntrada
        mensagemDeErro={this.state.mensagemDeErroDoEnvioDaConfirmacao}
        limparMensagemDeErro={this.limparMensagemDeErro}>
        {!cpf && !telefoneCelularComDigitosOcultos &&
          // eslint-disable-next-line no-restricted-globals
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }
        <div className="grade">
          <div className="grade__coluna">
            <LinkComIcone
              href="/login"
              onClick={() => history.push('/login')}
              cor="info"
              icone="far fa-long-arrow-left"
              posicaoDoIcone="esquerda"
              className="u-texto-negrito"> Voltar</LinkComIcone>
            <h2 className="titulo titulo_grande texto_cor-terciaria u-margem-superior-media u-margem-inferior-media">
              Quase lá!
            </h2>            
          </div>
          <div id="foco-inicial-do-teclado" className="grade__coluna" tabIndex="-1">
            {this.definirMensagemDeTipoDeContatoParaRecuperacaoDeSenha(tipoDeContato)}
                        
            {this.definirOpcaoEmailParaRecuperacaoDeSenha(emailComCaracteresOcultos, tipoDeContato)}            

            {this.definirOpcaoTelefoneCelularParaRecuperacaoDeSenha(telefoneCelularComDigitosOcultos, tipoDeContato)}
                          
          </div>            
          <form onSubmit={(event) => this.enviarCodigoDeConfirmacao(telefoneCelularComDigitosOcultos, emailComCaracteresOcultos, cpf, event)}
            className="formulario formulario_medio u-margem-inferior-media">                
            {!(telefoneCelularComDigitosOcultos || emailComCaracteresOcultos)  &&
              <Mensagem
                tipo="atencao"
                className="u-margem-pequena"
                icone="far fa-exclamation-circle">
                Atendimento presencial temporariamente <strong>suspenso</strong>.<br />
                Para atualizar sua inscrição e conseguir sua senha de acesso, agende seu atendimento:<br />
                <span className="grade__coluna"><strong> - Campo Grande/MS:</strong> agende o atendimento pelo telefone <a href="tel:08006473120"><strong>0800-647-3120.</strong></a><br /></span>
                                                          
                <span className="grade__coluna"><strong> - Demais cidades:</strong> procure a <strong>Prefeitura Municipal</strong> e atualize seus dados de contato da sua inscrição.<br /></span>
              </Mensagem>}

            {(telefoneCelularComDigitosOcultos || emailComCaracteresOcultos) &&
              <>
                <Mensagem
                  tipo="info"
                  className="u-margem-pequena"
                  icone="far fa-info-circle">
                  Caso estas não sejam mais suas informações de contato, agende seu atendimento:<br />                            
                  <span className="grade__coluna"><strong> - Campo Grande/MS:</strong> agende o atendimento pelo telefone <a href="tel:08006473120"><strong>0800-647-3120.</strong></a><br /></span>
                                                          
                  <span className="grade__coluna"><strong> - Demais cidades:</strong> procure a <strong>Prefeitura Municipal</strong> e atualize seus dados de contato da sua inscrição.<br /></span>
                </Mensagem>

                <div className="grade__linha">
                  <div className="grade__coluna">
                    <BotaoComTexto
                      cor="terciaria"
                      classesAuxiliares="botao_largura-total">
                      Enviar código
                    </BotaoComTexto>
                  </div>
                </div>
              </>
            }
          </form>
        </div>
      </LayoutDaPaginaDeEntrada> */
}
