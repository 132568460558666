import { useEffect, useState } from 'react';

const ValidadorDeSenha = ({ senha }) => {
  const [isSenhaValidada, setSenhaValidada] = useState({
    minCaracteres: false,
    hasNumber: false,
    hasLetter: false
  });

  const validadorDaSenha = (senha) => {
    
    const minCaracteres = senha.length >= 6 && senha?.length <= 12;
    const hasNumber = /[0-9]/.test(senha);
    const hasLetter = /[a-zA-Z]/.test(senha);

    setSenhaValidada({
      minCaracteres,
      hasNumber,
      hasLetter
    });
  };

  useEffect(() => {
    validadorDaSenha(senha);
  }, [senha]);


  const validadores = [
    {
      label: 'Mínimo 6 caracteres',
      valid: isSenhaValidada.minCaracteres
    },
    {
      label: 'Conter número',
      valid: isSenhaValidada.hasNumber
    },
    {
      label: 'Conter letra',
      valid: isSenhaValidada.hasLetter
    }
  ];

  return (
    <div className="Validador-de-senha">
      {validadores.map((item) => (
        <span className={`Validador-de-senha-item ${item.valid && 'Validador-de-senha-item--success'}`} key={item.label}>
          <dgx-icon name="doneCheck" size="1" />
          <dgx-text
            text={item.label}
            color={item.valid ? 'default' : 'neutral'}
            size="1"
            weight={item.valid ? 'medium' : 'regular'}
            as="p"
          ></dgx-text>
        </span>
      ))}
    </div>
  );
};

export default ValidadorDeSenha;
